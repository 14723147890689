import { getDefaultRoute } from '@local/central-portal-core/dist/src/routing/central/routing';
import type { CentralInstance } from '@local/login';
import { getCentralInstances, getSelectedInstanceState } from '@local/login';

export function getSelectedInstance(): CentralInstance | undefined {
    const selectedInstanceName = getSelectedInstanceState();
    if (!selectedInstanceName) {
        return undefined;
    }
    return getCentralInstances()?.servers.find(
        (server: CentralInstance) => server.host === selectedInstanceName,
    );
}

export function getDefaultRouteIncludingInstanceUuid() {
    let defaultRoute = getDefaultRoute();
    const selectedInstance = getSelectedInstance();
    if (!selectedInstance) {
        return defaultRoute;
    }

    const selectedInstanceUuid = selectedInstance.instanceUUID;
    if (defaultRoute.startsWith('/')) {
        defaultRoute = defaultRoute.substring(1);
    }
    return `/${selectedInstanceUuid}/${defaultRoute}`;
}
